import {Typography, styled, Box} from '@mui/material';
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Feature, MultiPolygon, Polygon} from 'geojson';
import {
    NkmMapboxMap,
    Coords,
    FitBounds,
    MapEvent,
    MapboxLayer,
    Popup,
    mapClickEvent,
    marker,
    position,
} from '@norkart/nkm-mapbox-map';
import {getMapState, getSearchState} from '../../store';
import 'mapbox-gl/dist/mapbox-gl.css';
import './mapStyle.css';
import HelpButton from '../../components/HelpButton/HelpButton';
import {WmsFeature} from '../../services/apiTjenestekatalogen';
import arealplanerConfig from '../../config';
import {helpObject} from '../../help/helpObject';
import {shouldShowNoPlanMapInfoMessage} from '../plan/PlanInfo/helpers';
import useKommuneInfo from '../../hooks/kommuneinfo/useKommuneInfo';
import {Arealplan} from '../../hooks/arealplaner/types';
import usePlanAreaLayer from '../../hooks/kart/usePlanAreaLayer';
import {
    setDifferenceLayer,
    addFitToBounds,
    refreshWmsLayers,
    setBorderLayers,
} from '../../store/map/actions';
import useWmsManager from '../../hooks/kart/useWmsManager';
import LoadingSpinner from '../../components/LoadingSpinner';
import useMeta from '../../hooks/meta/useMeta';
import {getBorderLayers} from './helpers/createBorderLayer';
import {createFitBoundsForFeature} from './helpers/layerHelpers';
import {getLayersToDraw} from './helpers/getLayersToDraw';
import {getDiffLayers} from './helpers/diffLayer';
import {formatToMapboxLayer} from './helpers/getMapboxLayer';
import {boundingBoxNorway} from './helpers/constants';

const NoPlanInfoContainer = styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    zIndex: 1100,
    pointerEvents: 'all', // explicit to be more obvious that this combined with zIndex, blocks click events,  which is wanted for the map when there is no plan info,
});

const NoPlanInfoTextWrapper = styled('div')({
    backgroundColor: '#ffffffdc',
    padding: '20px',
});

const NoPlanInfoText = styled(Typography)(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
        fontStyle: 'italic',
        paddingRight: '5px',
    },
}));

const MapText = styled('div')(({theme}) => ({
    backgroundColor: '#ffffff',
    border: '1px solid ' + theme.palette.secondary.main,
    position: 'absolute',
    bottom: '13px',
    right: 0,
    margin: '10px',
    padding: '5px',
}));

type Props = {
    clickableLayers?: {
        name: string;
    }[];
    extraLayers?: MapboxLayer[];
    onMapClick?: (event: mapClickEvent) => void;
    onLayerClick?: (event: mapClickEvent) => void;
    displaySearchedPropertyGeom?: boolean;
    displaySurroundingPlanWms?: boolean;
    visibleGfiFeature?: WmsFeature;
    displayClickedNeighbourPlan?: boolean;
    mapboxMarker?: Coords | marker;
    mapPopup?: Popup;
    controls?: {
        control: any;
        option?: position;
    }[];
    events?: MapEvent[];
    mapOptions?: any;
    plan?: Arealplan;
};

export default function BaseMap({
    clickableLayers,
    extraLayers = [],
    onMapClick,
    onLayerClick,
    displaySearchedPropertyGeom = false,
    displaySurroundingPlanWms = false,
    visibleGfiFeature,
    displayClickedNeighbourPlan,
    mapboxMarker,
    mapPopup,
    controls,
    events,
    mapOptions = {refreshExpiredTiles: false},
    plan,
}: Props) {
    const dispatch = useDispatch();
    const mapState = useSelector(getMapState);
    const searchState = useSelector(getSearchState);
    const kommuneinfo = useKommuneInfo();
    const wmsManager = useWmsManager();
    const meta = useMeta();
    const [error, setError] = useState<string | null>(null);

    const {data: planLayerState} = usePlanAreaLayer(plan);

    const [fitBounds, setFitBounds] = useState<FitBounds | undefined>(
        mapState.fitBounds
    );

    const noPlanMapInfo = plan ? shouldShowNoPlanMapInfoMessage(plan) : false;
    const wmsNotFound = mapState.wmsLayerState.wmsNotFound;

    useEffect(() => {
        if (mapState.fitBounds) {
            setFitBounds(mapState.fitBounds);
        }
    }, [mapState.fitBounds]);

    useEffect(() => {
        if (kommuneinfo.isFetched) {
            let municipalityGeom: Feature<MultiPolygon | Polygon> | undefined;
            try {
                municipalityGeom = {
                    type: 'Feature',
                    geometry: JSON.parse(kommuneinfo.data.KommuneGeometry),
                    properties: {kommune: kommuneinfo.data.KommuneNumber},
                };
            } catch {
                setError('Kommunegeometrien kunne ikke lastes');
                console.log('Kommunegeometrien kunne ikke lastes');
            }

            if (plan && planLayerState?.planAreaLayers) {
                if (mapState.wmsLayerState.planId !== plan.planId) {
                    //initialise layers for displaying a specific plan
                    console.log('Initialising map for plan');
                    try {
                        const diffLayers = getDiffLayers(
                            planLayerState.planAreaLayers,
                            boundingBoxNorway
                        );

                        const borderLayers = getBorderLayers(
                            planLayerState.planAreaLayers
                        );

                        if (!searchState.searchedPropertyGeom) {
                            dispatch(
                                addFitToBounds(
                                    createFitBoundsForFeature(
                                        planLayerState.planAreaLayers.combined
                                    )
                                )
                            );
                        }

                        dispatch(setDifferenceLayer(diffLayers));
                        dispatch(setBorderLayers(borderLayers));
                        wmsManager.triggerInitForPlan(plan);
                    } catch (e: any) {
                        console.log(
                            'Error while initialising layers for plan: ',
                            e.message
                        );
                        setError(e.message);
                    }
                } else {
                    dispatch(refreshWmsLayers(displaySurroundingPlanWms));
                }
            } else if (!plan && municipalityGeom) {
                //If no plan specified, configure map for general overview of municipality
                console.log('Initialising map for municipality');
                const borderLayer = formatToMapboxLayer(
                    municipalityGeom.geometry,
                    'municipalityBorderLayer',
                    {
                        'line-color': '#b50000',
                        'line-width': 4,
                        'line-dasharray': [1, 1],
                    },
                    undefined,
                    'line'
                );

                dispatch(
                    addFitToBounds(createFitBoundsForFeature(municipalityGeom))
                );
                dispatch(
                    setBorderLayers({
                        combined: borderLayer,
                    })
                );

                wmsManager.triggerInitForMunicipality();
            }
        }
    }, [
        plan,
        planLayerState,
        kommuneinfo.isFetched,
        displaySurroundingPlanWms,
    ]);

    const layersToDrawMemo = useMemo(
        () =>
            getLayersToDraw(
                mapState,
                searchState.searchedPropertyGeom,
                displaySearchedPropertyGeom,
                displaySurroundingPlanWms,
                visibleGfiFeature
            ).concat(extraLayers),
        [
            mapState.differenceLayer,
            mapState.borderLayers,
            mapState.wmsLayerState.availableWmsLayers,
            searchState.searchedPropertyGeom,
            displaySearchedPropertyGeom,
            displaySurroundingPlanWms,
            visibleGfiFeature,
            extraLayers,
        ]
    );

    function getMapInfo(): {text: string; helpObject: any} {
        const res = {
            text: 'Gjeldende arealplankart',
            helpObject: helpObject.PlankartInfo.GjeldendePlankart,
        };
        if (plan?.planStatusId === 3) {
            res.text = 'Gjeldende arealplankart';
            res.helpObject = helpObject.PlankartInfo.GjeldendePlankart;
        } else if (
            plan?.planStatusId === 1 ||
            plan?.planStatusId === 2 ||
            plan?.planStatusId === 6
        ) {
            res.text = 'Arealplankart under arbeid';
            res.helpObject = helpObject.PlankartInfo.UnderArbeid;
        } else if (
            plan?.planStatusId === 4 ||
            plan?.planStatusId === 5 ||
            plan?.planStatusId === 9 ||
            plan?.planStatusId === 10
        ) {
            res.text = 'Planen har ikke digitalt plankart';
            res.helpObject = helpObject.PlankartInfo.IkkeKart;
        }
        return res;
    }

    const displayMapError = () => {
        if (
            plan &&
            (noPlanMapInfo ||
                (planLayerState?.layerNotFound && !planLayerState.error))
        ) {
            return (
                <NoPlanInfoContainer>
                    <NoPlanInfoTextWrapper style={{display: 'flex'}}>
                        <NoPlanInfoText>
                            Planen har ikke digitalt plankart
                        </NoPlanInfoText>
                        <HelpButton
                            helpText={helpObject.PlankartInfo.IkkeKart}
                            type={'GjeldendePlankart noPlanMapInfo'}
                        />
                    </NoPlanInfoTextWrapper>
                </NoPlanInfoContainer>
            );
        } else if (wmsNotFound || planLayerState?.error || error) {
            return (
                <NoPlanInfoContainer>
                    <NoPlanInfoTextWrapper style={{display: 'flex'}}>
                        <NoPlanInfoText>
                            En feil har oppstått og kartet er ikke tilgjengelig
                            for øyeblikket
                        </NoPlanInfoText>
                    </NoPlanInfoTextWrapper>
                </NoPlanInfoContainer>
            );
        } else {
            return (
                <MapText style={{display: 'flex', alignItems: 'center'}}>
                    <Typography
                        style={{
                            fontStyle: 'italic',
                        }}
                    >
                        {getMapInfo().text}
                    </Typography>
                    <HelpButton
                        helpText={getMapInfo().helpObject}
                        type='GjeldendePlankart'
                    />
                </MapText>
            );
        }
    };

    function readyToRender() {
        if (planLayerState?.layerNotFound || noPlanMapInfo || error) {
            //sjekk om planomriss finnes. Hvis ikke er vi ferdig lastet og feilmelding skal vises
            console.log('fant ikke planomriss');
            return true;
        } else if (
            mapState.wmsLayerState.availableWmsLayers &&
            mapState.wmsLayerState.planId === plan?.planId
        ) {
            return true;
        }
        return (
            <Box
                sx={{
                    position: 'absolute',
                    top: '45%',
                    left: '45%',
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <LoadingSpinner />
            </Box>
        );
    }
    return (
        <>
            {readyToRender()}
            <NkmMapboxMap
                accessToken={arealplanerConfig.mapbox.accessToken}
                customIcons={[
                    {
                        url: '/nk_021-punktmarkoer-Roed-skygge-48px.png',
                        name: 'negative-marker',
                    },
                    {
                        url: '/nk_021-punktmarkoer-Gronn-skygge-48px.png',
                        name: 'positive-marker',
                    },
                    {
                        url: '/nk_021-punktmarkoer-Orange-skygge-48px.png',
                        name: 'neutral-marker',
                    },
                ]}
                clickableLayers={clickableLayers}
                styleUrl={arealplanerConfig.mapbox.styleUrl}
                wmsLayers={mapState.wmsLayerState.wmsLayers}
                fitBounds={fitBounds}
                shouldFly={false}
                navigationControls={{show: true, options: 'bottom-left'}}
                fireMapResize={true}
                recenterMap={false}
                onClick={onMapClick}
                onLayerClick={onLayerClick}
                onMapLoaded={() => setFitBounds(mapState.fitBounds)}
                onMoveend={() => setFitBounds(undefined)}
                layers={layersToDrawMemo}
                initFullscreen={true}
                popup={mapPopup}
                mapOptions={mapOptions}
                marker={mapboxMarker}
                controls={controls}
                events={events}
            />
            {displayMapError()}
        </>
    );
}
